import React from 'react';
import Name from '../../components/Account/Name';
import PrivateRoute from '../../components/PrivateRoute';
import ContextProvider from '../../components/ContextProvider';

const NamePage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account/name" component={Name} />
  </ContextProvider>
);

export default NamePage;

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@rebass/forms/styled-components';
import { updateProfile } from 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'rebass/styled-components';
import { fetchAndUpdateUser } from '../../utils/Auth';
import { LocaleContext } from '../ContextProvider';
import { Layout } from '../Layout';
import {
  BackLink,
  ContentContainer,
  ErrorMessage,
  Label,
  Row,
  Title,
} from '../LayoutComponents';
import { AuthContext } from '../PrivateRoute';
import Section from '../Section';

const NamePage = () => {
  const { t } = useTranslation(['name', 'commonLabels', 'errorLabels']);
  // RETRIEVE CONTENT
  const localeData = useContext(LocaleContext);
  const { rootPath } = localeData;
  const authData = useContext(AuthContext);
  const { authUser } = authData;

  // END RETRIEVE CONTENT
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);
  const [defaultName, setDefaultName] = useState<{
    value: string;
    isFetching: boolean;
  }>({ value: '', isFetching: true });
  const [name, setName] = useState<string>('');

  // Retrieve the user record from our DB and setup a change listener
  useEffect(() => {
    if (authUser) {
      setName(authUser.displayName || '');
      setDefaultName({ value: authUser.displayName || '', isFetching: false });
    }
  }, [authUser]);

  const updateName = async () => {
    setIsSaving(true);
    if (authUser) {
      setErrorSaving(false);
      try {
        await updateProfile(authUser, {
          displayName: name,
        });
        await fetchAndUpdateUser(authUser);
        navigate(`${rootPath}account`);
      } catch (error) {
        setErrorSaving(true);
        // couldn't update
      }
      setIsSaving(false);
    }
  };

  const canSave = name ? true : false;
  const spinningIcon = <FontAwesomeIcon icon={faSpinner} pulse />;
  return (
    <Layout
      title={`${t('title')} - CloudFit`}
      description={t('metaDescription')}
      colorTheme="light">
      <Section.Container backgroundColor="light" id="name.id">
        <BackLink to={`${rootPath}account`}>
          {t('back', { ns: 'commonLabels' })}
        </BackLink>
        <Flex
          as="form"
          variant="card"
          px={[0]}
          py={[3]}
          flexDirection="column"
          onSubmit={(event) => {
            event.preventDefault();
            updateName();
          }}>
          <Title title={t('title')} summary={t('description')} />
          <Row>
            <Label>{t('title')}</Label>
            <ContentContainer>
              <Input
                id="name"
                name="name"
                defaultValue={defaultName.value}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                disabled={defaultName.isFetching || isSaving}
              />
            </ContentContainer>
          </Row>

          <Row bottom>
            <Flex
              flexDirection="row"
              alignItems="center"
              width={[1]}
              justifyContent="flex-end">
              <Button
                type="button"
                variant="secondary"
                minWidth="100px"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${rootPath}account`);
                }}>
                {t('cancelButton', { ns: 'commonLabels' })}
              </Button>
              <Button
                type="submit"
                ml={[2]}
                disabled={defaultName.isFetching || isSaving || !canSave}
                minWidth="100px">
                {isSaving
                  ? spinningIcon
                  : t('submitButton', { ns: 'commonLabels' })}
              </Button>
            </Flex>
            <ErrorMessage visible={errorSaving} rightAlign>
              {t('errorCantSave', { ns: 'errorLabels' })}
            </ErrorMessage>
          </Row>
        </Flex>
      </Section.Container>
    </Layout>
  );
};

export default NamePage;
